
import { defineComponent } from "vue";
import {
  Col,
  Row,
  Input,
  Button,
  Cascader,
  Table,
  Pagination,
  Popover,
  message,
  Switch,
  Checkbox
} from "ant-design-vue";
import {
  SettingOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  FileAddFilled,
  StopOutlined,
  ReloadOutlined,
  ColumnHeightOutlined,
  TagsFilled,
  SyncOutlined,
  SearchOutlined,
  DownOutlined
} from "@ant-design/icons-vue";
import {
  getProvince,
  masterList,
  terminalDisable,
  customParameters,
  batchExport,
  channelSearch
} from "../../../api/terminalList.js";
import axios from "axios";
import { terminalListColumns } from "@/api/fixedData.js";
let { Group: CheckboxGroup } = Checkbox;
export default defineComponent({
  components: {
    DownOutlined,
    Col,
    Row,
    Input,
    Button,
    Cascader,
    SettingOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    CloudUploadOutlined, //上传图标
    CloudDownloadOutlined, //下载图标
    FileAddFilled, //新建图标
    StopOutlined, //电源图标
    TagsFilled,
    SyncOutlined,
    SearchOutlined,
    Table,
    Pagination,
    Popover,
    Switch,
    aCheckbox: Checkbox,
    aCheckboxGroup: CheckboxGroup
  },
  data() {
    return {
      //各个筛选框的值待修改
      terminalNameOrTerminalCode: "",
      kindsItem1: "",
      kindsItem2: "",
      channel: [],
      childChannel: "",
      address: "",
      //省市区值
      regionValue: [],
      //后端接收省市区
      options: [],
      channelOptions: [],
      cityCurrent: 0,
      //规模类型
      scaleType: [],
      scaleOptions: [
        {
          value: 1,
          label: "独立"
        },
        {
          value: 2,
          label: "连锁"
        }
      ],
      //table的数据
      data: [],
      //table的列名字
      columns: [],
      //复选框的选择结果
      selectedRowKeys: [],
      // 当前页数
      pageCurrent: sessionStorage.getItem("terminalListPageNum") || 1,
      // 每页数据数量
      pageSize: sessionStorage.getItem("terminalListPageSize") || 10,
      pageNum: sessionStorage.getItem("terminalListPageNum") || 1,
      //总数据量
      total: 0,
      tableHeight: "400px",
      searchFlag: false,
      //拓展字段
      customData: [],
      keepCustomData: [],
      //table的布局
      scroll: { x: "100%" },
      //批量操作按钮加载
      batchLoading: false,
      loading: true,
      //table的样式
      batchStyle: "middle"
    };
  },
  methods: {
    //左侧的多选列禁用筛选
    getCheckboxProps(record) {
      if (!record.status) {
        return { disabled: true };
      }
    },
    //复选结果
    onSelectChange(selectedRowKeys: any) {
      this.selectedRowKeys = selectedRowKeys;
    },
    //更换每页显示数量
    onShowSizeChange(current: any, pageSize: any) {
      this.pageNum = 1;
      this.pageCurrent = 1;
      this.pageSize = pageSize;
      sessionStorage.setItem("terminalListPageSize", pageSize + "");
      this.getTerminal();
    },
    handelRegionValue() {
      //
    },
    blurRegionValue() {
      if (this.cityCurrent !== 1) {
        this.cityCurrent = 0;
      }
    },
    //渠道懒加载
    loadChannelData(selectedOptions: any) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      let keepOption;
      if (this.cityCurrent === 0) {
        axios
          .get(
            `/api/terminal/v1/channelApi/list?parCode=${targetOption.channelCode}`
          )
          .then((res: any) => {
            targetOption.loading = false;
            let newOptions = res.data.data.map(item => {
              if (item.isHave) {
                return {
                  ...item,
                  value: item.channelCode,
                  label: item.channelName,
                  isLeaf: false,
                  level: item.level
                };
              } else {
                return {
                  ...item,
                  value: item.channelCode,
                  label: item.channelName,
                  isLeaf: true,
                  level: item.level
                };
              }
            });

            targetOption.children = newOptions;
            this.channelOptions = [...this.channelOptions];
            keepOption = [...newOptions];
            this.cityCurrent = this.cityCurrent + 1;
          });
      }
      if (this.cityCurrent === 1) {
        let flag = this.channelOptions.filter((i: any) => {
          return i.level == targetOption.level;
        }).length;
        if (flag) {
          this.cityCurrent = 0;
          this.loadChannelData(selectedOptions);
          return;
        }
        axios
          .get(
            `/api/terminal/v1/channelApi/list?parCode=${targetOption.channelCode}`
          )
          .then((res: any) => {
            targetOption.loading = false;
            let newOptions = res.data.data.map(item => {
              if (item.isHave) {
                return {
                  ...item,
                  value: item.channelCode,
                  label: item.channelName,
                  isLeaf: false,
                  level: item.level
                };
              } else {
                return {
                  ...item,
                  value: item.channelCode,
                  label: item.channelName,
                  isLeaf: true,
                  level: item.level
                };
              }
            });
            targetOption.children = newOptions;
            if (keepOption) {
              let areaFlag = keepOption.filter((i: any) => {
                return i.level == targetOption.level;
              }).length;

              if (areaFlag) {
                this.cityCurrent = 1;
                this.loadChannelData(selectedOptions);
                return;
              } else {
                this.channelOptions = [...this.channelOptions];
                this.cityCurrent = 1;
              }
            }
          });
      }
    },
    //省市区懒加载
    loadData(selectedOptions: any) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      let keepOption;
      if (this.cityCurrent === 0) {
        axios
          .get(
            `/api/base/v1/areaselect/city/find?provinceCode=${targetOption.code}`
          )
          .then((res: any) => {
            targetOption.loading = false;
            let newOptions = res.data.data.map(item => {
              return {
                ...item,
                value: item.name,
                label: item.name,
                isLeaf: false,
                level: item.level
              };
            });

            targetOption.children = newOptions;
            this.options = [...this.options];
            keepOption = [...newOptions];
            this.cityCurrent = this.cityCurrent + 1;
          });
      }
      if (this.cityCurrent === 1) {
        let flag = this.options.filter((i: any) => {
          return i.level == targetOption.level;
        }).length;
        if (flag) {
          this.cityCurrent = 0;
          this.loadData(selectedOptions);
          return;
        }
        axios
          .get(
            `/api/base/v1/areaselect/area/find?cityCode=${targetOption.code}`
          )
          .then((res: any) => {
            targetOption.loading = false;
            let newOptions = res.data.data.map(item => {
              return {
                ...item,
                value: item.name,
                label: item.name,
                level: item.level,
                isLeaf: true
              };
            });
            targetOption.children = newOptions;
            let areaFlag = keepOption.filter((i: any) => {
              return i.level == targetOption.level;
            }).length;

            if (areaFlag) {
              this.cityCurrent = 1;
              this.loadData(selectedOptions);
              return;
            } else {
              this.options = [...this.options];
              this.cityCurrent = 1;
            }
          });
      }
    },
    //弹出Popover 进行筛选
    handelColumns() {
      (this as any).columns = [...terminalListColumns, ...this.keepCustomData];
      (this as any).columns.push({
        title: "启用状态",
        slots: { customRender: "handle" },
        align: "left",
        fixed: "right",
        width: 80
      });
    },
    inputChange(item) {
      if (item.checked) {
        (this as any).customData.forEach((element, index) => {
          if (element.key == item.key) {
            (this as any).keepCustomData.splice(index, 0, element);
          }
        });
      } else {
        for (let i = 0; i < (this as any).keepCustomData.length; i++) {
          if (!(this as any).keepCustomData[i].checked) {
            (this as any).keepCustomData.splice(i, 1);
          }
        }
      }
      this.handelColumns();
    },
    //跳转到终端详情页面
    terminalDetails(id) {
      if (id === undefined) {
        id = "new";
      }
      if (this.$route.path.includes("terminalApp")) {
        this.$router.push(`/terminalApp/details/${id}`);
      } else {
        (this as any).$router.push(`/masterData/terminal/details/${id}`);
      }
    },
    //计算终端状态
    computedStatus(status) {
      switch (status) {
        case 0:
          return "禁用";
        case 1:
          return "启用";
        case 2:
          return "未激活";
        case 3:
          return "注销";
        case 4:
          return "删除";
        default:
          return "错误";
      }
    },
    //操作终端 单个
    terminalHandle(id, status) {
      status = status == "0" ? "1" : "0";
      terminalDisable({
        ids: [id],
        status: status
      }).then(res => {
        if (res.data.success) {
          if (status != 0) {
            message.success("门店已启用");
          } else {
            message.warning("门店已禁用");
          }
          this.selectedRowKeys.forEach((elem, index) => {
            if (elem === id) {
              this.selectedRowKeys.splice(index, 1);
            }
          });
          this.getTerminal();
        }
      });
    },
    //禁用终端 批量
    terminalDisable(id) {
      if (id.length == 0) {
        message.warning("请先勾选想要禁用终端");
        return;
      }
      let params = {
        ids: [...id],
        status: 0
      };
      terminalDisable(params).then(res => {
        if (res.data.success) {
          this.selectedRowKeys = [];
          message.success("批量禁用成功");
        }
        this.getTerminal();
      });
    },
    //获取终端列表
    getTerminal() {
      this.loading = true;
      let channelList = "";
      this.channel.forEach(item => {
        channelList += item + "/";
      });
      channelList = channelList.slice(0, channelList.length - 1);
      masterList({
        terminalNameOrTerminalCode: this.terminalNameOrTerminalCode,
        channelCode: channelList.split("/")[channelList.split("/").length - 1],
        province: this.regionValue[0],
        city: this.regionValue[1],
        area: this.regionValue[2],
        address: this.address,
        scaleType: this.scaleType[0] || null,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      })
        .then(res => {
          let result = res.data.data;
          this.total = result.totalRecord;
          result.data.forEach(item => {
            for (var attr in item.extraField) {
              item[attr] = item.extraField[attr];
            }
          });
          this.data = result.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //重置
    reset() {
      this.terminalNameOrTerminalCode = "";
      this.channel = [];
      this.regionValue = [];
      this.scaleType = [];
      this.address = "";
      this.search();
      this.searchFlag = false;
    },
    //搜索
    search() {
      this.searchFlag = true;
      this.pageNum = 1;

      this.getTerminal();
      this.pageCurrent = 1;
    },
    computedExtra(record) {},
    batchImport(type = "") {
      let query = "";
      if (type === "checkstatus") {
        query = `Check?type=${type}`;
      } else if (type === "edit") {
        query = `Edit?type=${type}`;
      } else if (type === 'match') {
        query = `Match?type=${type}`
      }
      if (this.$route.path.includes("terminalApp")) {
        this.$router.push(`/terminalApp/batchImport${query}`);
      } else {
        this.$router.push(`/masterData/terminal/batchImport${query}`);
      }
    },
    batchExport() {
      let data = {
        terminalNameOrTerminalCode: this.terminalNameOrTerminalCode || "",
        channelCode: this.channel[this.channel.length - 1] || "",
        province: this.regionValue[0] || "",
        city: this.regionValue[1] || "",
        area: this.regionValue[2] || "",
        address: this.address || "",
        scaleType: this.scaleType[0] || 0,
        status: "null"
      };
      batchExport(data).then((res: any) => {
        if (res.data.success) {
          message.success(
            '导出正在处理中，请稍后去消息中心查看下载(右上角"🔔")'
          );
        }
      });
      return;
    },
    //计算当前是否禁用状态
    computeStatus(status) {
      if (status == 0) {
        return false;
      } else {
        return true;
      }
    },
    setScaleType(scaleType) {
      if (scaleType == 1) {
        return "独立";
      } else if (scaleType == 2) {
        return "连锁";
      } else {
        return "";
      }
    }
  },
  watch: {
    //监听页码和页面数量变化
    pageCurrent() {
      if (this.searchFlag === false) {
        this.terminalNameOrTerminalCode = "";
        this.kindsItem1 = "";
        this.kindsItem2 = "";
        this.channel = [];
        this.childChannel = "";
        this.regionValue = [];
        this.scaleType = [];
        this.address = "";
      }
      this.selectedRowKeys = [];
      this.pageNum = this.pageCurrent;
      sessionStorage.setItem("terminalListPageNum", this.pageCurrent + "");
      this.getTerminal();
    }
  },
  mounted() {
    // this.tableHeight = window.screen.height - 628 + "px";
    // this.scroll.y = window.screen.height - 665;
    //获取省份
    getProvince().then((res: any) => {
      let newOptions = res.data.data.map(item => {
        return {
          ...item,
          value: item.name,
          label: item.name,
          isLeaf: false
        };
      });
      this.options = newOptions;
    });
    channelSearch({
      parCode: 0
    }).then((res: any) => {
      let newOptions = res.data.data.map(item => {
        if (item.isHave) {
          return {
            ...item,
            value: item.channelCode,
            label: item.channelName,
            isLeaf: false,
            level: item.level
          };
        } else {
          return {
            ...item,
            value: item.channelCode,
            label: item.channelName,
            isLeaf: true,
            level: item.level
          };
        }
      });
      this.channelOptions = newOptions;
    });
    //获取终端列表信息
    this.getTerminal();
    //获取拓展字段
    customParameters("terminal").then((res: any) => {
      let resArray = res.data.data.map((item, index) => {
        if (item.status) {
          return {
            title: item.extraName,
            checked: true,
            dataIndex: item.extraCode,
            key: index,
            width: 120
          };
        } else {
          return {};
        }
      });
      resArray = resArray.filter(item => {
        return item.title;
      });
      if (resArray.length) {
        this.customData = resArray;
        (this as any).keepCustomData = [...resArray];
      }
      (this as any).columns = terminalListColumns;
      this.columns = [...this.columns, ...this.customData];
      (this as any).columns.push({
        title: "启用状态",
        slots: { customRender: "handle" },
        align: "left",
        fixed: "right",
        width: 80
      });
      //
    });
  }
});
